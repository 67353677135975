import { v4 as uuidv4 } from 'uuid';

export const updateObjState = (setter, model, prop, val) => {
    let _model = Object.assign({}, model)
    _model[prop] = val
    setter(_model)
}
export const updateArrOfObjState = (setter, model, index, prop, val) => {
    let _model = [...model]
    _model[index] = Object.assign({}, _model[index], { [prop]: val })
    setter(_model)
}

export const getOriginalField = (field, originalFillableModel) => {
   
    let _model = [...originalFillableModel]

    let originalField = _model.find(existingField => existingField.id === field.id)
   
    return originalField
}

export const getOriginalOptionFromField = (field, originalFillableModel, option) => {
   
    let _model = [...originalFillableModel]

    let originalField = _model.find(existingField => existingField.id === field.id)
   
    if(originalField) {
        let currentOption = originalField.options.find(opt => opt.id === option.id)
        return currentOption
    }
}

export const updateArrOfObjStateFileWithId = (setter, field, originalFillableModel, downloadUrl, fileName) => {
    let _model = [...originalFillableModel]

    let fieldIndex = _model.findIndex(existingField => existingField.id === field.id)
    _model[fieldIndex] = Object.assign({}, _model[fieldIndex], { value: downloadUrl })
    _model[fieldIndex] = Object.assign({}, _model[fieldIndex], { fileName: fileName })
    setter(_model)
}

export const updateArrOfObjStateFilesWithId = (setter, field, originalFillableModel, filesToSave) => {
    let _model = [...originalFillableModel]

    let fieldIndex = _model.findIndex(existingField => existingField.id === field.id)
    
    _model[fieldIndex] = Object.assign({}, _model[fieldIndex], { files: filesToSave })

    setter(_model)
}

export const removeArrOfObjStateFilesWithId = (setter, field, originalFillableModel, file) => {
    let _model = [...originalFillableModel]
    let fieldIndex = _model.findIndex(existingField => existingField.id === field.id)
    if(!file) {
        _model[fieldIndex] = Object.assign({}, _model[fieldIndex], { files: [] })

    } else {
        let selectedFiles = _model[fieldIndex].files

        if(selectedFiles) {        
            selectedFiles =  selectedFiles.filter(selectedFile => !(selectedFile.fileName === file.fileName && selectedFile.value === file.value) )
            _model[fieldIndex] = Object.assign({}, _model[fieldIndex], { files: selectedFiles })
        }
    }

    setter(_model)
}

export const updateArrOfObjStateWithId = (setter, field, originalFillableModel, prop, val) => {
    let _model = [...originalFillableModel]
    let fieldIndex = _model.findIndex(existingField => existingField.id === field.id)
    _model[fieldIndex] = Object.assign({}, _model[fieldIndex], { [prop]: val })
    setter(_model)
}


//toggles an item in array
export const arrayToggle = (arr, item, other) => {
    let idx = arr.indexOf(item)
    if(idx > -1){
        if(!other) {
            arr.splice(idx, 1)
        }
    }else{
        arr.push(item)
    }
}

export const validateEmail = email => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email?.trim()).toLowerCase());
}

export const getDateFromMillis = milliseconds => {
    let date = new Date(milliseconds)
    return date.toLocaleString()
}

export const expired = (createDateMillis, hours) => {
    if(!hours) return false
    let currentDateMillis = +(new Date())
    let hoursMillis = parseInt(hours) * 60 * 60 * 1000
    return (currentDateMillis - createDateMillis) < hoursMillis
}

export const createFillableModel = model => {

    let fillableModel = []
    let fields = model.fields
    for(let field in fields){
        let fld = fields[field]
        // let fieldModel = {
        //     title: fld.title,
        //     required: fld.required,
        //     type: fld.type,
        //     value: fld.type === "multioption-singleanswer" || fld.type === "multioption-multianswer" ? [] : ""
        // }
        // fillableModel.push(fieldModel)
        fillableModel.push({...fld, value: (fld.type === "multioption-singleanswer" || fld.type ===  "multioption-singleanswer-image") || (fld.type === "multioption-multianswer" || fld.type === "multioption-multianswer-image") ? [] : ""})
    }

    return fillableModel
}

export const createSubmitableModel = fields => {
    let submitableModel = []
    for(let field in fields){
        let fld = fields[field]

        if((!fld.value || fld.value.length < 1) && !fld.files) continue
        
        let fieldModel = {
            value: fld.value,
            type: fld.type
        }

        if(fld.title) {
            fieldModel.title = fld.title
        }

        if(fld.text) {
            fieldModel.text = fld.text
        }

        if(fld.files) {
            fieldModel.files = fld.files
        }


        submitableModel.push(fieldModel)
    }
    return submitableModel
}

function parseValue(value) {
  return value ?? value?.trim()
}

export const hasError = fields => {
    for(let field of fields){
        if(!field.required && !parseValue(field?.value)) continue

        if(field.validation === "email") {
            if((field.required && field?.value.length < 1) || (!validateEmail(field.value))) return `'${field?.title && field.title !== "" ? field.title : field.text ?? ""}' does not have a valid email.`
        }
        else if(["short-text", "long-text", "number"].indexOf(field.type) > -1){
            if(field.required && !parseValue(field?.value)) return `'${field?.title && field.title !== "" ? field.title : field.text ?? ""}' is a required field`
        }
        else if(["file"].indexOf(field.type) > -1){
            if(field.required) {

                if(!field.multipleFiles ) {
                    if(!parseValue(field?.value)) {
                        return `'${field?.title && field.title !== "" ? field.title : field.text ?? ""}' is a required field`
                    }
                   
                } 
                else if(!field.files || field.files.length === 0) {
                        return `'${field?.title && field.title !== "" ? field.title : field.text ?? ""}' is a required field`

                }
            }
        }
        else {

            if(field.required && field?.value.length < 1) { 
                return `'${field?.title && field.title !== "" ? field.title : field.text ?? ""}' is a required field`
            }
        }
    }
    return false
}

export const isEmailField = field => {
   
    return field.title === "Enter your email" && field.type === "short-text" && field.page === 1 && field.required
}


export const parseId = (id, page) => {
    return (id.replaceAll(/[\W_]+/g, "_") + "_" + page).toLowerCase()
}

export const generateUid = () => {
    let id = uuidv4()
    return id
}

function getImageNameFromSrc(src) {
    return src.substring(src.lastIndexOf("/"))
}

export const setSelectedImage = (src) => {
    for(let img of document.getElementsByTagName('img')){
        if(getImageNameFromSrc(img.src) === getImageNameFromSrc(src)) {
            img.parentNode.click()
        }
    }    
}


