import { useEffect, useRef } from 'react';

function ConfirmationModal({ operation, yes, no }){
    const myRef = useRef();

    const handleClickOutside = e => {
        if (!myRef.current.contains(e.target)) {
            no();
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    });

    return (
        <div className="modal">
            <div className="modal-content confirmation" ref={myRef}>
                <span className="close" onClick={no}>&times;</span>
                <h3>{operation}?</h3>
                <br/>
                <button className="btn" onClick={yes}>Sim</button>
                &nbsp;
                <button className="btn" onClick={no}>Não!</button>
            </div>
        </div>
    )
}

export default ConfirmationModal