import { jsPDF } from "jspdf";
import axios from "axios";

export const buildBody = (date, id, email, title) => {
  return `Confirmamos a receção do seu pedido.
<br />
O preenchimento do formulário não é um processo automático.
<br />
A equipa de design irá enviar a proposta de design no prazo mais rápido possível.
<br />`;
};

export const getEmailInformation = (id) => {
  return {
    filename: `4CoachSports_${id}_.pdf`,
    subject: "O seu pedido está em processamento",
    cc: ["info@4coachsports.com"],
  };
};

export const getSubmissionAsAttachment = async (
  submission,
  filename,
  download
) => {
  let imgList = await getImagesDataUrl(submission);
  let pdfBody = await buildPdfBody(submission, imgList);

  return await handleDownloadPdf(filename, pdfBody, download);
};

const handleDownloadPdf = async (filename, doc, download) => {
  const pdf = new jsPDF("portrait", "pt", "a4");

  await pdf.html(doc, {
    width: 575,
    autoPaging: true,
    windowWidth: 575,
    margin: 10,
  });

  if (download) {
    pdf.save(filename);
  }

  return btoa(
    new Uint8Array(pdf.output("arraybuffer")).reduce(function (data, byte) {
      return data + String.fromCharCode(byte);
    }, "")
  );
};

const buildPdfBody = async (submission, imgList) => {
  let element = `<div id="order">`;

  for(let subm of submission){

    let files = []
    let file = ""

    if(subm.type === "file") {
      if(subm.files) {
        for(let file of subm.files) {
          let base64Image = await parseUrlToBase64Image(file.value)
          files.push(base64Image)
        }
      } else {
        let base64Image = await parseUrlToBase64Image(subm.value)
        file = base64Image        
      }
    }
    
    let field = `<div style="margin-top: 12px;
        background-color: #fff;
        border: 1px solid #dadce0;
        border-radius: 8px;
        margin-bottom: 12px;
        padding: 24px;
        padding-top: 22px;
        position: relative;">
        <h5>${subm.title ?? subm.text}</h5>
        <br/>
        ${
          subm.type === "multioption-singleanswer" ||
          subm.type === "multioption-singleanswer-image" ||
          subm.type === "multioption-multianswer" ||
          subm.type === "multioption-multianswer-image"
            ? subm.value.map((v, index) =>
                v?.image?.src
                  ? `
                    <img alt="" width="60%" src="${getImgFromImgArray(imgList, v.id)}" />
                    <p key=${index}> - ${v.text}</p>
                    <br />
                `
                  : `
                    <p key=${index}> - ${
                      v.value ? `${v.text}: ` + v.value : v.text
                    } </p>
                    <br />
                    `
              )
            : subm.type === "file"
            ? subm.files ? `${files.map((v, index) => `<img width="60%" alt="" src="${v}" />`).join(' ')}` 
                           :   `<img width="60%" alt="" src="${file}" />`
            : `<p>${subm.value}</p>
            <br />
            `
        }
        </div>`;

      

    element += field;
  }

  element += `</div>`;

  return element;
};

async function parseUrlToBase64Image(url) {
  let parsedFile = url.split('?')
  let base64 = await getImageBase64(url)

  let imgType = parsedFile[0].substring(
    parsedFile[0].lastIndexOf(".") + 1
  );

  return `data:image/${imgType};base64,${base64}`
}

const getImagesDataUrl = async (submission) => {
  let imgArray = [];

  let imageSubmissions = submission.filter(
    (subm) =>
      (subm.type === "multioption-singleanswer" ||
        subm.type === "multioption-singleanswer-image" ||
        subm.type === "multioption-multianswer" ||
        subm.type === "multioption-multianswer-image") &&
      subm?.value.filter((img) => img?.image?.src).length > 0
  );

  let imageSubmissionValues = imageSubmissions.flatMap((subm) => {
    return subm.value.flatMap((x) => x);
  });

  for (let field of imageSubmissionValues) {
    let base64 = field.image.src;

    if (base64.indexOf("data:image") === -1) {
      base64 = await getImageBase64(field.image.src);

      let imgType = field.image.src.substring(
        field.image.src.lastIndexOf(".") + 1
      );

      imgArray.push({
        base64: `data:image/${imgType};base64,${base64}`,
        id: field.id,
      });
    } else {
      imgArray.push({
        base64: base64,
        id: field.id,
      });
    }
  }

  return imgArray;
};

const getImgFromImgArray = (imgList, id) => {
  let img = imgList.find((img) => img.id === id);

  return img.base64;
};

const getImageBase64 = async (src) => {
  let img = await axios.get(src, {
    responseType: "arraybuffer",
  });

  let base64 = btoa(
    new Uint8Array(img.data).reduce(function (data, byte) {
      return data + String.fromCharCode(byte);
    }, "")
  );

  return base64;
};
