import { useState } from 'react'
import { Link } from 'react-router-dom'

import { getDateFromMillis } from '../utils'
import { deleteForm, duplicateForm } from "../db"

import RenderPlainForm from "../components/RenderPlainForm"
import Pagination from "./ui/Pagination"

import ComponentModal from './modal/ComponentModal'
import ConfirmationModal from './modal/ConfirmationModal'

function FormCard({ form, onDelete, reloadForms }){
    const [preview, setPreview] = useState(false)
    const [loading, setLoading] = useState(false)
    const [loadingDelete, setLoadingDelete] = useState(false)
    const [loadingDuplicate, setLoadingDuplicate] = useState(false)
    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const [showDuplicateModal, setShowDuplicateModal] = useState(false)
    const [page, setPage] = useState(1)

    const cancelDelete = () => {
        setShowDeleteModal(false)
    }

    const cancelDuplicate = () => {
        setShowDuplicateModal(false)
    }

    const performDelete = async () => {
        setLoading(true)
        setLoadingDelete(true)
        await deleteForm(form.id)
        setLoadingDelete(false)
        setLoading(false)
        onDelete(form.id)
    }

    const performDuplicate = async () => {
        setLoading(true)
        setLoadingDuplicate(true)
        await duplicateForm(form)
        setLoadingDuplicate(false)
        await reloadForms()
        setShowDuplicateModal(false)
        setLoading(false)
    }

  
    function getMaxPage(){
        return Math.max(...form.fields.map(o=>o.page)); 
    }
    function incrementPage() {        
        var result = page + 1;

        form.currentPage = result
        //validate min and max pages
        if(result <= getMaxPage()) {
            setPage(result);        
        }

    }


    function decrementPage() {

        var result = page - 1;
        form.currentPage = result

        //validate min and max pages
        if(result > 0) {
            setPage(result);
        }    
    }



    return (
        <div className="card">
            <div className='mb-1'>
                <h2 className="title">
                    <span>{form.title}</span>
                </h2>
                <h5>{getDateFromMillis(form.createdAt)}</h5>

            </div>
            <a href={`${window.location.origin}/fill/${form.id}`} rel="noreferrer" className="link mb-1" target="_blank">{`${window.location.origin}/fill/${form.id}`}</a>
            <p className="card-nav">
                <Link className="nav-item form-card" to={"/submissions/" + form.id} >Submissões</Link>
                <Link className="nav-item form-card" to={"/edit/" + form.id} >Editar</Link>
                <span className="nav-item form-card" onClick={() => setShowDeleteModal(true)}>{ loadingDelete ? <span className="spinner red"></span> : <span>Apagar</span>}</span>
                <span className="nav-item form-card" onClick={() => setShowDuplicateModal(true)}>{ loadingDuplicate ? <span className="spinner red"></span> : <span>Duplicar</span>}</span>
            </p>
            {
                preview &&
                    <ComponentModal close={() => setPreview(false)} additionalClass={"preview"} component={
                        <>
                            <RenderPlainForm model={form} />
                            <Pagination decrementPage={decrementPage} 
                                incrementPage={incrementPage} 
                                getMaxPage={getMaxPage}
                                page={page}
                                loading={loading}
                                />
                        </>
                    } />
            }

            {
              showDeleteModal && <ConfirmationModal operation={`Deseja apagar o formulário ${form.id} com a data ${getDateFromMillis(form.createdAt)}`}
                                                    yes={performDelete}
                                                    no={cancelDelete}
                                                    />
            }

            {
              showDuplicateModal && <ConfirmationModal operation={`Deseja duplicar o formulário ${form.id}`}
                                                    yes={performDuplicate}
                                                    no={cancelDuplicate}
                                                    />
            }
        </div>
    )
}

export default FormCard