import ImagePicker from 'react-image-picker'
import 'react-image-picker/dist/index.css'

const contents = require.context('../assets/images/intro/', false, /\.(jpg|png|jpeg|gif|JPG|PNG|JPEG|GIF)$/)

let imageList = contents.keys().map( (key) => {

  let content = contents(key)

  if(content.indexOf("base64") === -1) {
    content = window.location.origin + contents(key)
  }

  return {
    name: key,
    url: content
  }
})

function SelectImageIntro({onImageSelected, keyName}){

  function onPick(selectedImage) {
    onImageSelected(selectedImage);
  }

    return (
      <div>
        <ImagePicker 
          key={keyName}
          images={imageList.map((img, i) => ({src: img.url, value: img.name}))}
          onPick={onPick}
        />
      </div>
    )
}

export default SelectImageIntro