import { useState } from 'react'

import { createFillableModel, createSubmitableModel, hasError } from "../utils"

import { submitForm, sendSubmitEmail } from "../db"

import Pagination from "./ui/Pagination"
import PageSectionReactiveField from "./PageSectionReactiveField"
import PageSectionHeadersDomain from "./PageSectionHeadersDomain.js"

import RenderReactiveFormComponents from "./RenderReactiveFormComponents"

function RenderReactiveForm({ model, onSubmitted }){
    const [fillableModel, setFillableModel] = useState(createFillableModel(model))
    const [loading, setLoading] = useState(false)
    const [err, setErr] = useState("")
    const [page, setPage] = useState(1)

    function getMaxPage(){
        return Math.max(...model.fields.map(o=>o.page)); 
    }
    function incrementPage() {
        setErr("")
        
        let currentPageFields = fillableModel.filter(field => field.page === page)
        let error = hasError(currentPageFields)
        if(error) return setErr(error)

        var result = page + 1;

        //validate min and max pages
        if(result <= getMaxPage()) {
            setPage(result);        
        }

    }


    function decrementPage() {
        setErr("")

        var result = page - 1;

        //validate min and max pages
        if(result > 0) {
            setPage(result);
        }    
    }

    const handleSubmit = async () => {
        setErr("")
        if(loading) return

        let error = hasError(fillableModel, model.id)
        if(error) return setErr(error)

        setLoading(true)

        let submitableModel = createSubmitableModel(fillableModel)
        let email = fillableModel.find(field => field.id === "main-email")?.value.trim()
        let date =  new Date().toUTCString()

        let submissionId
        try{
            
          submissionId = await submitForm(submitableModel, date, model.id, email)
           
        }catch(e){
            setErr(e.message)
            setLoading(false)
            return
        }

        try{
            
            await sendSubmitEmail(submitableModel, date, submissionId, email, model)
           
        }catch(e){
        }

        setLoading(false)
        onSubmitted()
    }

    return (
        <>
            <div className="main-form mt-1 cards-container">
                <div className='form-container'>
                    {
                        PageSectionHeadersDomain.hasHeaders(fillableModel, page) ?
                        <>
                            <PageSectionReactiveField backgroundColor={model.backgroundColor}  header={PageSectionHeadersDomain.getForm(fillableModel, page).header} field={PageSectionHeadersDomain.getForm(fillableModel, page).field}  originalFillableModel={fillableModel} setFillableModel={setFillableModel} page={page}  /> 
                            
                            <RenderReactiveFormComponents originalFillableModel={fillableModel} fillableModel={PageSectionHeadersDomain.getForm(fillableModel, page).fields} setFillableModel={setFillableModel} page={page} loading={loading} setLoading={setLoading}  />
                        </>
                        :
                        <>
                        
                            <RenderReactiveFormComponents originalFillableModel={fillableModel} fillableModel={PageSectionHeadersDomain.getFieldsWithoutHeaders(fillableModel, page)} setFillableModel={setFillableModel} page={page} loading={loading} setLoading={setLoading} />
                        </>
                    }

                    

                    {err && <p className="err mb-1">{err}</p>}
                </div>

            </div>


                <Pagination decrementPage={decrementPage} 
                            incrementPage={incrementPage} 
                            getMaxPage={getMaxPage}
                            page={page}
                            handleSubmit={handleSubmit}
                            loading={loading}
                            />
                
        </>
    )
}

export default RenderReactiveForm
