import { useState, useEffect } from "react"
import { auth, firestore, storage } from "./firebase"
import { getAnalytics, logEvent } from "firebase/analytics";
import { buildBody, getEmailInformation } from "../utils/email"

export const signup = async (email, pwd) => auth.createUserWithEmailAndPassword(email.trim(), pwd.trim())
export const login = async (email, pwd) => auth.signInWithEmailAndPassword(email.trim(), pwd.trim())
export const logout = () => {
    localStorage.setItem("gfc-user", "")
    return auth.signOut()
}

export const sendSubmitEmail = async (submission, date, submissionId, email, form) => {
    let emailInfo = getEmailInformation(submissionId)
    let subject =  emailInfo.subject
    let carbonCopy =  emailInfo.cc
    let html = await buildBody(date, submissionId, email, form.title)
    await firestore.collection('mail').add({
            to: email,
            cc: carbonCopy,
            message: {
            subject: subject,
            html: html
        },
    })
}

export const useAuthenticated = () => {
    const [user, setUser] = useState({})
    useEffect(() => {
        auth.onAuthStateChanged(usr => setUser(usr))
    }, [])
    return user
}

export const createForm = formModel => {
    const user = JSON.parse(localStorage.getItem("gfc-user"))
    return firestore.collection("forms").add({...formModel, uid: user.uid})
}

export const editForm = async (formModel) => {
    const user = JSON.parse(localStorage.getItem("gfc-user"))

    return firestore.collection("forms").doc(formModel.id).update({...formModel, uid: user.uid})
}

export const getForms = async () => {
    const user = JSON.parse(localStorage.getItem("gfc-user"))
    let docs = await firestore.collection("forms").get({
        uid: user.uid
    })
    docs = docs.docs
    let forms = docs.map(doc => ({...doc.data(), id: doc.id}))
    return forms
}

export const getForm = async ops => {
    let docs = await firestore.collection("forms").get(ops)
    let doc = docs.docs.find(doc => doc.id === ops.id)

    if(!doc) {
        return undefined
    }
    doc = {...doc.data(), id: doc.id }
    
    const analytics = getAnalytics();
    
    logEvent(analytics, 'first_visit');


    return doc
}

export const logError = async error => {

    const user = JSON.parse(localStorage.getItem("gfc-user"))
    return firestore.collection("errors").add({...error, uid: user.uid})

}
export const deleteForm = async formId => {
    let submissions = await firestore.collection("submissions").where("formId", "==", formId).get()
    submissions = submissions.docs
    for(let submission of submissions) {
        await firestore.collection("submissions").doc(submission.id).delete()
    }
    return firestore.collection("forms").doc(formId).delete()
}

export const duplicateForm = async form => {
    const user = JSON.parse(localStorage.getItem("gfc-user"))
    
    let newFormModel = form;

    newFormModel.title += ` Copy ${new Date().toLocaleString()} ` 
   
    return firestore.collection("forms").add({...newFormModel, uid: user.uid})
}


export const uploadFile = (file, fileName) => {
    let ref = storage.ref("files/" + fileName)
    return ref.put(file)
}

export const submitForm = async (submission, date, formId, email) => {

    let submissionResult = await firestore.collection("submissions").add({
        submission,
        formId,
        date: date,
        agent: navigator.userAgent,
        email: email
    })

    return submissionResult.id
} 

export const getSubmissionDocument = async id => {
    let docs = await firestore.collection("submissions").get(`${id}`)
    docs = docs.docs
    let submissions = docs.map(doc => ({...doc.data(), id: doc.id}))

    let document = submissions.find(doc => doc.id === id)

    return document
}

export const getSubmissions = async opts => {
    
    let docs = await firestore.collection("submissions").get(opts)
    docs = docs.docs
    let submissions = docs.map(doc => ({...doc.data(), id: doc.id}))
    
    submissions  =  submissions.filter(doc => doc.formId === opts.formID)

    return submissions
}


export const deleteSubmission = async opts => {

    let submissions = await firestore.collection("submissions").where("formId", "==", opts.formId).get()
    let submission = submissions.docs.find(doc => doc.id === opts.id)

    return await firestore.collection("submissions").doc(submission.id).delete()
}

