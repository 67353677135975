import { isEmailField } from "../utils"
import EditIcon   from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

function RenderEditMode({field, headerWrapper, openEditModal, deleteField, moveFieldUp, moveFieldDown,  component, reverse}) {

    function deleteItem(field)  {

        if(headerWrapper && (field.id === headerWrapper.header.id)) {
            let toDelete = [headerWrapper.header.id]

            if(headerWrapper?.field?.id) {
                toDelete.push(headerWrapper.field.id)
            }

            deleteField(toDelete)
        } else {
            deleteField([field.id])
        }

    }

    function canEdit(field) {
        
        return ["file"].indexOf(field.type) === -1 
    }


    return(
        (!isEmailField(field)) ?
        <div className={"edit-mode " + (reverse ? "reverse" : "") }>
            <div className="edit-mode-controls">
                {
                    deleteField &&
                    <IconButton aria-label="delete" onClick={e => deleteItem(field)}>
                        <DeleteIcon />
                    </IconButton>
                }
                {
                    (openEditModal && canEdit(field))&&
                    <IconButton aria-label="edit" onClick={e => openEditModal(field)}>
                        <EditIcon />
                    </IconButton>
                }
                {
                    moveFieldUp &&
                    <IconButton aria-label="up" onClick={e => moveFieldUp(field)}>
                        <ArrowUpwardIcon />
                    </IconButton>
                }
                {
                    
                    moveFieldDown &&
                    <IconButton aria-label="down" onClick={e => moveFieldDown(field)}>
                        <ArrowDownwardIcon />
                    </IconButton>
                }
            </div>
            <div className="edit-mode-preview">
                {component}
            </div>
        </div>
        :
        <>  
            {component} 
        </>
    )
}

export default RenderEditMode
