import * as React from "react";
import TextField from "@mui/material/TextField";

function TextInput({
  type,
  required,
  controlKey,
  label,
  onChange,
  name,
  preview,
  fillableValue,
  validation,
  other,
}) {
  function getText() {
    let text = fillableValue?.value ?? "";

    if (validation === "email") {
      text = text.trim();
    }

    return text;
  }
  function getStyle() {
    let style = { width: "60%" };

    if (other) {
      style.marginLeft = "0.5em";
      style.verticalAlign = "middle";
    }
    return style;
  }

  function getTopStyle() {
    let style = {
      // eslint-disable-next-line no-useless-computed-key
      verticalAlign: "middle",
    };
    return style;
  }

  return (
    <span style={getTopStyle()}>
      {label && (
        <label style={getTopStyle()}>
          {label}
          {required && <span className="err">*</span>}
        </label>
      )}

      {type && type === "number" && (
        <TextField
          type="number"
          InputLabelProps={{
            shrink: true,
            disableAnimation: true,
          }}
          key={controlKey}
          required={required}
          variant="standard"
          value={getText()}
          onChange={onChange}
          disabled={preview ?? false}
        />
      )}
      {type && type === "short-text" && (
        <TextField
          name={name ?? ""}
          key={controlKey ?? ""}
          sx={getStyle()}
          required={required ?? false}
          variant="standard"
          InputLabelProps={{
            disableAnimation: true,
          }}
          value={getText()}
          onChange={onChange}
          disabled={preview ?? false}
        />
      )}

      {type && type === "long-text" && (
        <TextField
          key={controlKey}
          required={required}
          sx={{ width: "70%" }}
          multiline
          variant="standard"
          onChange={onChange}
          InputLabelProps={{
            disableAnimation: true,
          }}
          value={getText()}
          disabled={preview ?? false}
        />
      )}
    </span>
  );
}

export default TextInput;
