import RenderReactiveFormComponents from "./RenderReactiveFormComponents"

function PageSectionReactiveField({  header, field, backgroundColor, controlKey, setFillableModel, originalFillableModel, page }){

    function getFieldArray() {
        return new Array(field);
    }

    function getBackgroundColor() {
        let style = { };

        if (backgroundColor) {
            style.backgroundColor = backgroundColor
        }

        return style;
    }
    return (
        <>
            {
                header &&
                <div className="heading-wrapper page-header" key={controlKey}>
                    <div className="head section-header" style={getBackgroundColor()}>
                        <label className="block">{header.header}</label>  
                    </div>

                    {
                        field && 
                        <div className="text section-header">
                            <RenderReactiveFormComponents originalFillableModel={originalFillableModel} fillableModel={getFieldArray()} setFillableModel={setFillableModel} page={page} />
                        </div>
                    }
                </div>
            }
        </>
    )
}

export default PageSectionReactiveField

