import React, { useState, createRef, useEffect } from 'react'
import SelectImage from '../SelectImage.js'
import { v4 as uuidv4 } from 'uuid';

function AddInformationField({inputType, add, update, close, getMaxPage, fieldToUpdate}){
    const [err, setErr ] = useState("")
    const inputImageRef = createRef()
    const [imageOption, setImageOption] = useState({})
    const [text, setText] = useState("")
    const [youtube, setYoutube] = useState("")
    const [title, setTitle] = useState("")
    const [required, setRequired] = useState(false)
    const [selectedPage, setPage] = useState()

    const addField = () => {
        if(inputType.indexOf("banner") !== -1) {
            if(!text.trim()) return setErr("Text is required")
        }
        
        if(inputType.indexOf("image") !== -1) {
            if(!imageOption?.src) return setErr("Image is required")
        }

        if(inputType.indexOf("youtube") !== -1) {
            if(!youtube) return setErr("Youtube link is required")
        }


        if(!selectedPage) return setErr("You need to select a page")

        
        add({
            id: uuidv4(),
            title,
            text,
            required,
            youtube,
            image: imageOption,
            type: inputType,
            page: selectedPage
        })
        close()
    }

    const updateField = () => {
        if(inputType.indexOf("banner") !== -1) {
            if(!text.trim()) return setErr("Text is required")
        }

        if(inputType.indexOf("image") !== -1) {
            if(!imageOption?.src) return setErr("Image is required")
        }

        if(inputType.indexOf("youtube") !== -1) {
            if(!youtube) return setErr("Youtube link is required")
        }

        
        if(!selectedPage) return setErr("You need to select a page")

        fieldToUpdate.title = title
        fieldToUpdate.text = text
        fieldToUpdate.youtube = youtube
        fieldToUpdate.required = required
        fieldToUpdate.image = imageOption
        fieldToUpdate.page = selectedPage
        
        update(fieldToUpdate)

        close()
    }

     useEffect(() => {
        
        let pageElement = document.getElementById("currentPage")

        if(fieldToUpdate) {
            let textElement = document.getElementById("currentText")
            let titleElement = document.getElementById("currentTitle")
            let requiredElement = document.getElementById("currentRequired")
            let youtubeElement = document.getElementById("currentYoutube")
            
            if(textElement) {
                textElement.value = fieldToUpdate.text
                setText(fieldToUpdate.text)
            }

            if(titleElement) {
                titleElement.value = fieldToUpdate.title
                setTitle(fieldToUpdate.title)
            }

            if(requiredElement) {
                requiredElement.checked = fieldToUpdate.required
                setRequired(fieldToUpdate.required)
            }

            if(youtubeElement) {
                youtubeElement.value = fieldToUpdate.youtube
                setYoutube(fieldToUpdate.youtube)
            }

            setPage(fieldToUpdate.page)
            pageElement.value = fieldToUpdate.page

        } else {

            let maxValue = getMaxPage()
            setPage(maxValue)
            pageElement.value = maxValue

        }
    },[getMaxPage, fieldToUpdate])

    return (
        <div>
            {   inputType.indexOf("banner") !== -1 && 
                (
                    <div className="input">
                        <label>Banner</label>
                        
                        <textarea id="currentText" cols="40" rows="5" onChange={e => setText(e.target.value)}></textarea>
                    </div>
                )
                    
            }

            {   inputType.indexOf("image") !== -1  && 
                (
                    <div className="input">
                        <SelectImage id="currentImage" onImageSelected={selectedImage => setImageOption(selectedImage)} ref={inputImageRef} />
                    </div>
                    
                )
            }

            {   inputType.indexOf("text") !== -1 && 
                (
                    <>
                        <div className="input">
                            <label>Title</label>

                            <textarea id="currentTitle" placeholder='Title of the field' cols="40" rows="5" onChange={e => setTitle(e.target.value)}></textarea>
                        </div>

                        <div className="input inline">
                            <label>Required: </label>
                            <input type="checkbox" id="currentRequired" onChange={() => setRequired(!required)} />
                        </div>
                    </>
                                        
                )
            }

            {   inputType.indexOf("youtube") !== -1 && 
                (
                    <>
                        <div className="input">
                            <input type="text" id="currentYoutube"  placeholder='Youtube embed video link' onChange={e => setYoutube(e.target.value)} />
                        </div>
                    </>
                                        
                )
            }

            <div className="input inline">
                <label>Page: </label>
                <input type="number" id="currentPage" onChange={e => setPage(parseInt(e.target.value))} />
            </div>

            {err && <p className="err mb-1">{err}</p>}
            {!fieldToUpdate && <button className="btn" onClick={addField}>Adicionar Campo</button>}
            {fieldToUpdate && <button className="btn" onClick={updateField}>Actualizar Campo</button>}
   
        </div>
    )
}

export default AddInformationField