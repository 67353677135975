import { useState, useEffect } from 'react'

import { useParams } from 'react-router-dom'

import { getForm } from "../db"

import RenderReactiveForm from '../components/RenderReactiveForm'

import { expired } from '../utils'
import Heading from '../components/Heading'

function Fill(){
    const { id } = useParams()

    const [form, setForm] = useState(null)
    const [msg, setMsg] = useState('')
    const [submitted, setSubmitted] = useState(false)
    const [loading, setLoading] = useState(true)
    const [intro, setIntro] = useState(true)

    useEffect(() => {
        if(!localStorage.getItem('gfc-user')) return
        const fetchData = async () => {
            try{
                let frm = await getForm({ id })
                if(frm) {
                    document.title = frm.title
                    setForm(frm)
                }
                setLoading(false)
            }catch(e){
                setLoading(false)
                setMsg(e.message)
            }
        }
        fetchData()
    }, [id])

    return (
        <div className="layer">
            <div className="container-layer">
                {
                   
                    (intro && form?.imageIntro?.src) && 
                    <div class="intro">
                        <img alt="" src={form.imageIntro.src}></img>
                        <button className="btn" style={{ width: '100%', backgroundColor: form.backgroundColor, color: form.color}} onClick={() => setIntro(false)}>Entrar</button>

                    </div>
                }

                {
                    !(intro && form?.imageIntro?.src) && 
                    <div>
                        <Heading form={form} />
                        {
                            loading ? <p className="text-center mt-1"><span className="spinner"></span></p>
                            : msg ? <h3 className="msg mt-1">{msg}</h3>
                            : submitted ? <h3 className="msg mt-1">{form ? (form.endMessage || "Thank you for submitting the form") : "Unknown state"}</h3>
                            : form ? expired() ? <h3 className="msg mt-1">This form has been expired</h3>
                            : <RenderReactiveForm model={form} onSubmitted={() => setSubmitted(true)} />
                            : <h3 className="msg mt-1">Form not found</h3> 
                        }
                    </div>
                }
            </div>
        </div>
    )
}

export default Fill