import { useState, createRef, useEffect } from 'react'
import SelectImage from '../SelectImage.js'
import { v4 as uuidv4 } from 'uuid';
import RenderEditMode from "../RenderEditMode"

function AddMultiOptionField({ inputType, add, update, close, getMaxPage, fieldToUpdate }){
    const [err, setErr ] = useState("")
    const [opterr, setOpterr ] = useState("")

    const [title, setTitle] = useState("")
    const [required, setRequired] = useState(false)
    const inputRef = createRef()
    const inputImageRef = createRef()
    const [options, setOptions] = useState([])
    const [option, setOption] = useState("")
    const [imageOption, setImageOption] = useState({})
    const [imageBanner, setImageBanner] = useState({})
    const [selectedPage, setPage] = useState()

    const addField = () => {
        if(!title.trim()) return setErr("Title is required")
        if(title.trim().length < 4) return setErr("Title should be atleast 4 characters long")
        if(options.length < 1) return setErr("Atleast one option is required")
        if(!selectedPage) return setErr("You need to select a page")

        add({
            id: uuidv4(),
            title,
            required,
            options,
            imageBanner,
            page: selectedPage,
            type: inputType
        })
        close()
    }

    const updateField = () => {
        if(!title.trim()) return setErr("Title is required")
        if(title.trim().length < 4) return setErr("Title should be atleast 4 characters long")
        if(options.length < 1) return setErr("Atleast one option is required")
        if(!selectedPage) return setErr("You need to select a page")

        fieldToUpdate.title = title
        fieldToUpdate.options = options
        fieldToUpdate.required = required
        fieldToUpdate.page = selectedPage
        fieldToUpdate.imageBanner = imageBanner
        
        update(fieldToUpdate)

        close()
    }


    const addOption = () => {

        if(!option.trim()) return setOpterr("Option is required")
        if(inputType.indexOf("image") !== -1 && (!imageOption || !imageOption.src || !imageOption.src.trim())) return setOpterr("Image Option is required")

        let _opts = [...options]
        _opts.push({
            id: uuidv4(),
            text: option,
            image: imageOption
        })

        let otherOption = _opts.findIndex(x => x?.type === "other-option");

        if(otherOption !== -1) {

           if(otherOption !== _opts.length - 1) {
            let other = _opts[otherOption]

            _opts.splice(otherOption, 1)

            _opts.push(other)

           }
        }

        setOption("")
        setOptions(_opts)
        inputRef.current.value = ""
        inputImageRef.current = {}
        setOpterr("")
        
    }
    

    const deleteField = (field) => {
        let _opts = [...options]
        _opts = _opts.filter(opt => !field.find(match => match === opt.id))
        setOptions(_opts)
    }

    const addOtherOption = () => {

        let _opts = [...options]
        let otherExists = _opts.findIndex(x => x.type === "other-option") !== -1;

        if(!otherExists) {
            _opts.push({
                id: uuidv4(),
                type: "other-option",
                text: "Other"
            })
            setOption("")
            setOptions(_opts)
            inputRef.current.value = ""
            inputImageRef.current = {}
            setOpterr("")
        }
    }

    useEffect(() => {
        
        let pageElement = document.getElementById("currentPage")
        let titleElement = document.getElementById("currentTitle")
        let requiredElement = document.getElementById("currentRequired")

        if(fieldToUpdate) {
            titleElement.value = fieldToUpdate.title
            requiredElement.checked = fieldToUpdate.required
            pageElement.value = fieldToUpdate.page

            if(inputType.indexOf('image') !== -1) {
                setOptions(fieldToUpdate.options)
            }

            setImageBanner(fieldToUpdate.imageBanner)
            setTitle(fieldToUpdate.title)
            setRequired(fieldToUpdate.required)
            setPage(fieldToUpdate.page)
        } else {

            let maxValue = getMaxPage()
            pageElement.value = maxValue
            setPage(maxValue)
        }
    },[getMaxPage, fieldToUpdate, inputType])



    
    return (
        <div>
            <div className="input">
                <label>Titulo do campo</label>
                <input type="text" id="currentTitle" placeholder="" onChange={e => setTitle(e.target.value)} />
            </div>
                <div className="input">
                    <label>Banner (optional)</label>
                    <div className="input grey-container p-1">
                        <SelectImage onImageSelected={selectedImage => setImageBanner(selectedImage)} ref={inputImageRef} />
                    </div>
                </div>
            { 
                options.length > 0 && (
                    <div className="mb-1">
                        <p className="b">Options</p>
                        { options.map((option, index) => (
                            <RenderEditMode field={option} deleteField={deleteField} reverse={true} component={ 
                                <div className="input inline mb-1" key={index}>
                                    {
                                        option?.image?.src && 
                                            <img class="multi-option-image add-field"  alt="" src={ option.image.src } /> 
                                    }
                                    
                                    <input type={ (inputType === "multioption-singleanswer" || inputType === "multioption-singleanswer-image") ? "radio" : "checkbox" } className="mr-1" name="inputs" />
                                    <label>{ option?.text ?? option }</label>
                                    
                                    {
                                        option?.type &&  
                                            <input type={ option.type } disabled="disabled" className="mr-1" name="inputs" /> 
                                    }
                                </div>
                            } />
                        )) }
                        
                    </div>
                )
            }

            <div className="input grey-container p-1">
                <label>Titulo da opção</label>
                <input type="text" className="mb-1" placeholder="" onChange={e => setOption(e.target.value)} ref={inputRef} />
                
                { inputType.indexOf('image') !== -1 && <SelectImage onImageSelected={selectedImage => setImageOption(selectedImage)} ref={inputImageRef} /> }
              
                {opterr && <p className="err mb-1 text-small">{opterr}</p>}
                

                <div className="input inline">
                    <button class="btn" onClick={addOption}>Add Option</button>
                    <button class="btn" onClick={addOtherOption }>Add Other</button>
                </div>

            </div>
            <div className="input inline">
                <label>Required: </label>
                <input type="checkbox" id="currentRequired" onChange={() => setRequired(!required)} />
            </div>
            <div className="input inline">
                <label>Page: </label>
                <input type="number" id="currentPage" onChange={e => setPage(parseInt(e.target.value))} />
            </div>
            {err && <p className="err mb-1">{err}</p>}
            {!fieldToUpdate && <button className="btn" onClick={addField}>Adicionar Campo</button>}
            {fieldToUpdate && <button className="btn" onClick={updateField}>Actualizar Campo</button>}
        </div>
    )
}

export default AddMultiOptionField