import { useState, createRef } from 'react'
import { useNavigate } from "react-router-dom"
import SelectImageBanner from './SelectImageBanner_2.js'
import SelectImageIntro from './SelectImageIntro_2.js'

import AddFieldModal from "../components/modal/AddFieldModal"
import RenderPlainForm from "../components/RenderPlainForm"
import { ColorPicker } from "../components/ui/ColorPicker";

import { updateObjState } from "../utils"

import { createForm as saveForm } from "../db"
import 'draft-js/dist/Draft.css';

function Create(){
    const inputImageRef = createRef()
    const inputImageIntroRef = createRef()

    const [showAddModal, setShowAddModal] = useState(false)    
    const [showUpdateModal, setShowUpdateModal] = useState(false) 
    const [updateField, setUpdateField] = useState() 
       
    const [inputType, setInputType] = useState("text")
    const [err, setErr] = useState("")

    const [loading, setLoading] = useState(false)
    const [imageBanner, setImageBanner] = useState({})
    const [imageIntro, setImageIntro] = useState({})
    
    const history = useNavigate()

    const openAddModal = inputType => {
        setUpdateField()

        setShowAddModal(true)
        setInputType(inputType)
    }

    const openEditModal = (field) => {
        setUpdateField(field)
        setInputType(field.type)
        setShowUpdateModal(true)
        setShowAddModal(true)       
    }

    const deleteField = (fieldToDelete) => {
        let _model = Object.assign({}, formModel)
        _model.fields = _model.fields.filter(field => !fieldToDelete.find(match => match === field.id))
        setFormModel(_model)
    }

    const moveFieldUp = (field) => {
        let _model = Object.assign({}, formModel)

        let currentPosition = _model.fields.findIndex(match => match.id === field.id)
      
        _model.fields.splice(currentPosition, 1);
        _model.fields.splice(currentPosition - 1, 0, field);

        setFormModel(_model)
    }
    
    const moveFieldDown = (field) => {
        let _model = Object.assign({}, formModel)

        let currentPosition = _model.fields.findIndex(match => match.id === field.id)
      
        _model.fields.splice(currentPosition, 1);
        _model.fields.splice(currentPosition + 1, 0, field);

        setFormModel(_model)
    }


    const [formModel, setFormModel] = useState({
        title: "",
        topBanner: {},
        createdAt: +(new Date()),
        fields: [
            {
                id:"main-email",
                title: "Enter your email",
                type: "short-text",
                validation: "email",
                page: 1,
                required: true
            }
        ],
        paginationEnabled: true,
        currentPage: 1,
        endMessage: "",
        expiration: ""
    })

    const setFormHeaderBackgroundColor = color => {
        let _model = Object.assign({}, formModel)
        _model.backgroundColor = color
        setFormModel(_model)
    }
    const setFormHeaderColor = color => {
        let _model = Object.assign({}, formModel)
        _model.color = color
        setFormModel(_model)
    }

    const addFieldToFormModel = field => {
        let _model = Object.assign({}, formModel)
        _model.fields.push(field)
        setFormModel(_model)
    }

    const updateFieldToFormModel = updatedField => {
        let _model = Object.assign({}, formModel)

        let oldField = _model.fields.findIndex(field => field.id === updatedField.id)
        _model.fields[oldField] = updatedField

        setFormModel(_model)

        setUpdateField()
    }

    

    const sectionTypes = ["page-section-header"]

    const informationTypes = ["information-banner", "information-banner-text", "information-banner-image", "information-banner-image-text", "information-image-text", "information-image", "information-youtube", "information-youtube-banner"]

    const inputTypes = ["short-text", "long-text", "number", "file"]

    const inputMultipleTypes = ["multioption-singleanswer", "multioption-multianswer"]
    const inputMultipleImageTypes = ["multioption-singleanswer-image", "multioption-multianswer-image"]

    function getMaxPage(){
        return Math.max(...formModel.fields.map(o=>o.page)); 
    }

    function incrementPage() {
        
        var result = formModel.currentPage + 1;

        //validate min and max pages
        if(result <= getMaxPage()) {
            setPage(result);        
        }
    }

    function decrementPage() {
        var result = formModel.currentPage - 1;

        //validate min and max pages
        if(result > 0) {
            setPage(result);
        }    
    }

    function setPage(result) {
        let _model = Object.assign({}, formModel)
        _model.currentPage =result;

        setFormModel(_model)

    }



    
    const createForm = async () => {
        if(loading) return
        setErr("")

        if(!formModel.title.trim()) return setErr("Title is required")
        if(formModel.title.trim().length < 5 || formModel.title.trim().length > 50) return setErr("Title should be 5 - 50 characters long")

        if(formModel.expiration.trim() && formModel.expiration < 1) return setErr("Validity should be at least an hour")

        if(formModel.fields.length < 2) return setErr("You need to add at least one field")

        formModel.topBanner = imageBanner
        formModel.imageIntro = imageIntro
        
        setLoading(true)
        try{
            await saveForm(formModel)
            setLoading(false)
            history("/forms")
        }catch(e){
            setErr(e.message)
            setLoading(false)
        }
    }


    return (
        <div className="create-form">
            <h1 className="heading">Criar um novo formulário</h1>
            
            <div className="form">

                <div className="input">
                    <label>Banner (opcional)</label>

                    <SelectImageBanner onImageSelected={selectedImage => setImageBanner(selectedImage)} ref={inputImageRef} />
                </div>

                <div className="input">
                    <label>Imagem de Introdução (opcional)</label>

                    <SelectImageIntro onImageSelected={selectedImage => setImageIntro(selectedImage)} ref={inputImageIntroRef} />
                </div>

                <div className="input">
                    <ColorPicker label="Tema - Imagem de fundo: " initialColor={"#0b1a98"} onColorChange={setFormHeaderBackgroundColor} />
                </div>

                <div className="input">
                    <ColorPicker label="Tema - Cor de Texto: " initialColor={"#000000"} onColorChange={setFormHeaderColor} />
                </div>

                <div className="input">
                    <label>Titulo</label>
                    <input type="text" placeholder="O titulo aparece no topo de todas as páginas e é usado para identificar o formulário." onChange={e => updateObjState(setFormModel, formModel ,"title", e.target.value)} />
                </div>

                {formModel.fields.length > 0 && <RenderPlainForm model={formModel} openEditModal={openEditModal} deleteField={deleteField} moveFieldUp={moveFieldUp} moveFieldDown={moveFieldDown}/>}

                <div className="pages">
                    <button className="btn" onClick={decrementPage}>«</button>
                    <span>                Page: {formModel.currentPage} of {getMaxPage()}                </span>
                    <button className="btn" onClick={incrementPage}>»</button>
                    
                </div>

                <div className="input">
                    <label>Mensagem final</label>
                    <input type="text" placeholder="Representa a mensagem final que é mostrada ao utilizador quando completa um formulário." onChange={e => updateObjState(setFormModel, formModel ,"endMessage", e.target.value)} />
                </div>

                <div className="input">
                    <label>Validade (opcional)</label>
                    <input type="number" placeholder="Permite limitar o numero de horas em que o formulário pode ser preenchido." onKeyDown={e => {if(e.key==='.' || e.key==='-'){e.preventDefault()}}} onChange={e => updateObjState(setFormModel, formModel ,"expiration", e.target.value)} />
                </div>

            </div>

            <p className="mb-2 text-right">
                { err && <p className="err text-right mb-1">{err}</p> }
                <button className="btn" onClick={createForm}>{ loading ? <span className="spinner white"></span> : <span>Submeter</span>}</button>
            </p>
            

            <div className="add-field-container grey-container">
                <h3>Campos</h3>
            </div>

            <div className="add-field-container grey-container">
                <p>Adicionar campos de secções</p>
                { sectionTypes.map((inputType, index) => <button className="btn" key={index} onClick={() => openAddModal(inputType)}>{inputType.replaceAll("-", " ")}</button>)}
            </div>

            <div className="add-field-container grey-container">
                <p>Adicionar campos informativos</p>
                { informationTypes.map((inputType, index) => <button className="btn" key={index} onClick={() => openAddModal(inputType)}>{inputType.replaceAll("-", " ")}</button>)}
            </div>

            <div className="add-field-container grey-container">
                <p>Adicionar campos normais</p>
                { inputTypes.map((inputType, index) => <button className="btn" key={index} onClick={() => openAddModal(inputType)}>{inputType.replaceAll("-", " ")}</button>)}
            </div>
            <div className="add-field-container grey-container">
                <p>Adicionar campos de escolha multipla</p>
                { inputMultipleTypes.map((inputType, index) => <button className="btn" key={index} onClick={() => openAddModal(inputType)}>{inputType.replaceAll("-", " ")}</button>)}
            </div>
            <div className="add-field-container grey-container">
                <p>Adicionar campos de escolha multipla com imagens</p>
                { inputMultipleImageTypes.map((inputType, index) => <button className="btn" key={index} onClick={() => openAddModal(inputType)}>{inputType.replaceAll("-", " ")}</button>)}
            </div>
            
            { (showAddModal || showUpdateModal) && <AddFieldModal inputType={inputType}  
                                             close={() => {
                                                setShowAddModal(false)  
                                                setShowUpdateModal(false)
                                             } } 
                                             add={addFieldToFormModel} 
                                             getMaxPage={() =>  {return getMaxPage()}} 
                                             fields={formModel.fields} 
                                             update={updateFieldToFormModel} 
                                             fieldToUpdate={updateField}/> }


        </div>
    )
}

export default Create