import { useEffect, useRef } from 'react';

function ComponentModal({ component, close, additionalClass }){
    const myRef = useRef();

    const handleClickOutside = e => {
        if (!myRef.current.contains(e.target)) {
            close();
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    });

    return (
        <div className="modal">
            <div className={"modal-content " + additionalClass} ref={myRef}>
                <span className="close" onClick={close}>&times;</span>

                {component} 
            </div>
        </div>
    )
}

export default ComponentModal