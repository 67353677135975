import RenderFormComponents from "./RenderFormComponents"
import PageSectionField from "./PageSectionField"
import PageSectionHeadersDomain from "./PageSectionHeadersDomain.js"
import RenderEditMode from "./RenderEditMode"

function RenderPlainForm({ model, openEditModal, deleteField, moveFieldUp, moveFieldDown }){

    return (
        <div className="grey-container mb-1">
            <h1 className="mb-1">Preview</h1>
            {
                PageSectionHeadersDomain.hasHeaders(model, model.currentPage) ?
                <>
                
                    <RenderEditMode field={PageSectionHeadersDomain.getForm(model, model.currentPage).header}
                                    headerWrapper={PageSectionHeadersDomain.getForm(model, model.currentPage)} 
                                    openEditModal={openEditModal} 
                                    deleteField={deleteField}  
                                    component={
                        <PageSectionField backgroundColor={PageSectionHeadersDomain.getForm(model, model.currentPage).backgroundColor} header={PageSectionHeadersDomain.getForm(model, model.currentPage).header} field={PageSectionHeadersDomain.getForm(model, model.currentPage).field} openEditModal={openEditModal} deleteField={deleteField} /> 
                    } />

                    <RenderFormComponents fields={PageSectionHeadersDomain.getForm(model, model.currentPage).fields} openEditModal={openEditModal} deleteField={deleteField} moveFieldUp={moveFieldUp} moveFieldDown={moveFieldDown} />
                </>
                :
                <>
                    
                    <RenderFormComponents fields={PageSectionHeadersDomain.getFieldsWithoutHeaders(model, model.currentPage)} openEditModal={openEditModal} deleteField={deleteField} moveFieldUp={moveFieldUp} moveFieldDown={moveFieldDown} />
                </>
            }
        </div>
    )
}

export default RenderPlainForm