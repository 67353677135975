import { useEffect } from "react"
import { Link, useLocation, useNavigate } from "react-router-dom"

import { useAuthenticated, logout } from "../db"

function Navbar(){
    const user = useAuthenticated()
    const location = useLocation()
    const history = useNavigate()

    useEffect(() => {
        if(user){
            localStorage.setItem("gfc-user", JSON.stringify(user))
            if(location.pathname === "/signup" || location.pathname === "/login"){
                history("/")
            }
        }else{
            if(location.pathname === "/create" || location.pathname === "/forms" || location.pathname.slice(0, 12) === "/submissions"){
                history("/login")
            }
        }
    }, [user, location, history])

    function visible() {
        let visible =  window.location.pathname.indexOf("/fill/") === -1 && window.location.pathname.indexOf("/order/") === -1 

        return visible
    }

    return (
        <>  {
            visible() &&
                <div className="navbar-wrapper">
                    <div className="navbar container">
                        <a href="/" className="brand">4coachsports</a>
                        <nav className="nav">
                            { user ? (
                                <span>
                                    <Link to="/forms">Formulários</Link>
                                    <Link to="/create">Criar Formulário</Link>
                                    <span onClick={logout}>Encerrar Sessão</span>
                                </span>
                            ) : (
                                <span>
                                    
                                    <Link to="/login">Iniciar Sessão</Link>
                                </span>
                            )}
                        </nav>
                    </div>
                </div>
            }
        </>
    )
}

export default Navbar
