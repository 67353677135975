import { isEmailField } from "../utils"

function filterByPage(model, page) {
    let fields =  model?.fields ?? model 
    return fields?.filter((field) => field.page === page);
}

function hasHeaders(model, page) {
    return filterByPage(model, page)?.filter((field) => field.type === "page-section-header").length > 0;
}

function getHeader(model, page) {
    return filterByPage(model, page)?.filter((field) => field.type === "page-section-header")[0];
}

function getFieldsWithoutHeaders(model, page) {
    return filterByPage(model, page)?.filter((field) => field.type !== "page-section-header");
}

function getForm(model, page) {
    let fields = getFieldsWithoutHeaders(model, page)?.filter(field => !isEmailField(field))

    let header = getHeader(model, page)

    let field = fields ? fields[0] : undefined
    let parsedFields = fields?.filter((field) => field.id !== fields[0].id)

    if(field) {
        field.headerPair = true
    }

    let headerWrapper= {
        header: header,
        field: field,
        fields: parsedFields ?? [],
        backgroundColor: model.backgroundColor
    }

    return  headerWrapper;
}

const pageSectionHeadersDomain = {
    filterByPage: filterByPage,
    hasHeaders: hasHeaders,
    getHeader: getHeader,
    getFieldsWithoutHeaders: getFieldsWithoutHeaders,
    getForm: getForm
}

export default pageSectionHeadersDomain