import { useState } from 'react'
import SubmissionDetailModal from './modal/SubmissionDetailModal'
import ConfirmationModal from './modal/ConfirmationModal'
import {deleteSubmission, sendSubmitEmail} from '../db/index'
import {getSubmissionAsAttachment, getEmailInformation} from '../utils/email'

function SubmissionCard({ formId, submission, submissions, submissionScope, updateSubmissions, form }){
    const [show, setShow] = useState(false)
    const [showDeleteModal, setShowDeleteModal] = useState(false)

    const hideOrShow = () => {
        setShow(!show)
    }

    const cancelDelete = () => {
        setShowDeleteModal(false)
    }

    const performDelete = async () => {
        setShowDeleteModal(false)
        await deleteSubmission({
            formId: formId, 
            id: submissionScope.id
        })

        let submissionsOpt = [...submissions].filter(sub => sub.id !== submissionScope.id)
        updateSubmissions(submissionsOpt)
    }
    
    const sendEmail = async () => {
        try {
            await sendSubmitEmail(submission, submissionScope.date, submissionScope.id, submissionScope.email, form)
        }
        catch(e) {
            alert(e)
        }
    }
    
    return (
        <div className="card submission-detail">
           <h4>Id:</h4>
           <p>{submissionScope.id}</p>
           <h4>Data:</h4>
           <p>{submissionScope.date}</p>
           <h4>Email:</h4>
           <p>{submissionScope.email}</p>
           <br/>
           <p className="card-nav">
            
               
                <span className="nav-item form-card" onClick={hideOrShow}>Ver Detalhes</span>
                <span className="nav-item form-card" onClick={() => getSubmissionAsAttachment(submission, getEmailInformation(submissionScope.id).filename, true)}>Descarregar PDF</span>
                <span className="nav-item form-card" onClick={() => sendEmail()}>Reenviar Email</span>
                <span className="nav-item form-card" onClick={() => setShowDeleteModal(true)}>Apagar</span>
            </p>

            {
                show && <SubmissionDetailModal submission={submission} submissionScope={submissionScope} close={hideOrShow} />
            }

            {
              showDeleteModal && <ConfirmationModal operation={`Deseja apagar a submissão ${submissionScope.id} com a data ${submissionScope.date}`}
                                                    yes={performDelete}
                                                    no={cancelDelete}
                                                    />
            }
            
        </div>
    )
}

export default SubmissionCard