import { useState, useEffect } from 'react'

import { uploadFile } from "../db"
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';

function FileField({ fieldModel, originalFillableModel, onCompleted, onCompletedFiles, onRemoveFiles, loading, setLoading }){
    const [selectedFile, setSelectedFile] = useState()
    const [selectedFileNamesList, setSelectedFileNamesList] = useState([])
    const [progress, setProgress] = useState(0)
    const [showProgress, setShowProgress] = useState(false)
    const [uploadingCount, setUploadingCount] = useState(0)
    const [uploadingTotalCount, setUploadingTotalCount] = useState(0)
    const [err, setErr] = useState("")

    const handleFile = async e => {
        if(selectedFile?.fileName) {
            setErr(`Maximum files to upload was exceeded, you can only upload 1 file.`)
            return
        }

        setErr("")
        setProgress(0)
        let file = e.target.files[0]

        let ext = file.name.split('.').pop().toLowerCase()

        if(!file) return
        if(fieldModel.accepted.indexOf(ext) === -1) return setErr("Choose a file with one of the following extensions: " + fieldModel.accepted.join(", "))
        //check if file size is bigger than 500MB
        if(file.size > 500*1024*1024) return setErr("File size should be less than than 500MB")
        
        setShowProgress(true)

        setSelectedFile(file)

        setLoading(true)

        setUploadingTotalCount(1)
        setUploadingCount(0)

        let fileName = +(new Date()) + "-" + file.name
        let task = uploadFile(file, fileName)
        task.on("state_changed", snapshot => {
            let  percentage = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
            setProgress(percentage)
        }, err => setErr(err.message))
        let snapshot = await task
        let downloadUrl = await snapshot.ref.getDownloadURL()
        setLoading(false)
        setShowProgress(false)
        setUploadingCount(1)

        onCompleted(downloadUrl, file.name)
    }

    const handleFiles = async e => {
        setErr("")
        setProgress(0)
        let files = Array.from(e.target.files)

        if(fieldModel?.filesToUpload > 0 && (files.length > fieldModel.filesToUpload)) {
            setErr(`Maximum files to upload was exceeded, you can only upload ${fieldModel.filesToUpload} files.`)
           
            return
        }

        if(fieldModel?.filesToUpload > 0 && selectedFileNamesList && (selectedFileNamesList.length >= fieldModel.filesToUpload)) {
            setErr(`Maximum files to upload was exceeded, you can only upload ${fieldModel.filesToUpload} files.`)
            
            return
        }

        if((selectedFileNamesList.length + files.length) > fieldModel.filesToUpload) {
            setErr(`Maximum files to upload was exceeded, you can only upload ${fieldModel.filesToUpload} files.`)
            
            return
        }
        let _selectedFileNamesList = [...selectedFileNamesList]

        for(const selectedFile of _selectedFileNamesList) {
            files = files.filter(file =>!(file.name === selectedFile.fileName))
        }

        var filesToLoad = files.length
        var filesLoaded = 0

        setLoading(true)

        setUploadingTotalCount(filesToLoad)
        setUploadingCount(0)

        for (const file of files) {

            let ext = file.name.split('.').pop().toLowerCase()

            if(!file) return
            if(fieldModel.accepted.indexOf(ext) === -1) return setErr("Choose a file with one of the following extensions: " + fieldModel.accepted.join(", "))
            //check if file size is bigger than 500MB
            if(file.size > 500*1024*1024) return setErr("File size should be less than than 500MB")

            setShowProgress(true)

            let fileName = +(new Date()) + "-" + file.name
            let task = uploadFile(file, fileName)

            task.on("state_changed", snapshot => {
                let  percentage = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                setProgress(percentage)
            }, err => setErr(err.message))

            let snapshot = await task
            let downloadUrl = await snapshot.ref.getDownloadURL()
            let fileUpload = {value: downloadUrl, fileName: file.name}
            _selectedFileNamesList.push(fileUpload)
           
            filesLoaded++
            setUploadingCount(filesLoaded)

            if(filesLoaded === filesToLoad) {
                setLoading(false)
                setShowProgress(false)
            }
        }

        if(files.length === 0) {
            setLoading(false)
            setShowProgress(false)
        }

        setSelectedFileNamesList(_selectedFileNamesList)


        onCompletedFiles(_selectedFileNamesList)

    }

    const deleteFile = file => {
        let _selectedFileNamesList = [...selectedFileNamesList]
        _selectedFileNamesList = _selectedFileNamesList.filter(selectedFile => !(file.fileName === selectedFile.fileName && file.value === selectedFile.value))

        if(_selectedFileNamesList.length === 0) {
            setShowProgress(false)
        }
        setSelectedFileNamesList(_selectedFileNamesList)
        onRemoveFiles(file)

        setErr("")
    }
    
    const deleteSeletedFile = file => {
        setSelectedFile()
        onRemoveFiles(file)

        setShowProgress(false)


        setErr("")
    }

    useEffect(() => {
       if(originalFillableModel?.fileName) {
            setSelectedFile(originalFillableModel)
       }

    },[originalFillableModel, setSelectedFile, setProgress, setShowProgress])


    return (
        <div className={!fieldModel.headerPair ? "input card": "input"}>
            <label>{fieldModel.title}{fieldModel.required && <span className="err">*</span>}</label>
           
                {
                    fieldModel.multipleFiles ? 
                        <>
                         <div className="file-field">

                            <input type="file" className="file" multiple="multiple" id={fieldModel.title.replace(" ", "")} onClick={e => e.target.value=null} onChange={handleFiles} />
                            <label className="btn" htmlFor={fieldModel.title.replace(" ", "")}>add files</label>
                            {
                                (uploadingCount !== uploadingTotalCount) && 
                                <span>Uploading {uploadingCount}/{uploadingTotalCount}</span>
                            }
                         </div>
                        

                            {
                                selectedFileNamesList.length > 0 &&
                                <div >
                                    <ul>
                                    {  
                                            selectedFileNamesList.map(file => (
                                                <li>
                                                    {file.fileName}
                                                    <IconButton aria-label="delete" onClick={e => deleteFile(file)}>
                                                        <DeleteIcon />
                                                    </IconButton>
                                                </li>
                                            ))
                                    }
                                    </ul>
                                </div>
                            }

                           
                        </>
                        :
                        <>
                         <div className="file-field">
                            <input type="file" className="file" id={fieldModel.title.replace(" ", "")} onClick={e => e.target.value=null} onChange={handleFile} />
                            <label className="btn" htmlFor={fieldModel.title.replace(" ", "")}>add file</label>
                            {
                                (uploadingCount !== uploadingTotalCount) && 
                                <span>Uploading {uploadingCount}/{uploadingTotalCount}</span>
                            }
                         </div>
                         {
                            selectedFile?.fileName &&
                                <div className="file-field">
                                    <ul>
                                        <li>
                                            {selectedFile.fileName}
                                            <IconButton aria-label="delete" onClick={e => deleteSeletedFile(selectedFile)}>
                                                <DeleteIcon />
                                            </IconButton>
                                        </li>
                                    </ul>
                                </div>
                         }
                         

                        </>
                }
                { 
                    showProgress && 
                    <>
                        <div className="progress-bar">
                            <div className="progress" style={{width: progress + "%"}}></div>
                        </div> 
                    </>

                }
                { err && <p className="err">{err}</p> }
        </div>
    )
}

export default FileField