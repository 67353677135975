import { useState, useEffect } from 'react'
import { v4 as uuidv4 } from 'uuid';

function AddTextField({inputType, add, update, close, getMaxPage, fieldToUpdate}){
    const [err, setErr ] = useState("")

    const [title, setTitle] = useState("")
    const [required, setRequired] = useState(false)
    const [selectedPage, setPage] = useState()

    const addField = () => {
        if(!title.trim()) return setErr("Title is required")
        if(title.trim().length < 3) return setErr("Title should be atleast 3 characters long")
        if(!selectedPage) return setErr("You need to select a page")

        add({
            id: uuidv4(),
            title,
            required,
            type: inputType,
            page: selectedPage
        })
        close()
    }

    const updateField = () => {
        if(!title.trim()) return setErr("Title is required")
        if(title.trim().length < 3) return setErr("Title should be atleast 3 characters long")
        if(!selectedPage) return setErr("You need to select a page")

        fieldToUpdate.title = title
        fieldToUpdate.required = required
        fieldToUpdate.page = selectedPage
        
        update(fieldToUpdate)

        close()
    }

    useEffect(() => {
        
        let pageElement = document.getElementById("currentPage")
        let titleElement = document.getElementById("currentTitle")
        let requiredElement = document.getElementById("currentRequired")

        if(fieldToUpdate) {
            titleElement.value = fieldToUpdate.title
            requiredElement.checked = fieldToUpdate.required
            pageElement.value = fieldToUpdate.page
            
            setTitle(fieldToUpdate.title)
            setRequired(fieldToUpdate.required)
            setPage(fieldToUpdate.page)
        } else {

            let maxValue = getMaxPage()
            pageElement.value = maxValue
            setPage(maxValue)
        }
    },[getMaxPage, fieldToUpdate])


    return (
        <div>
            <div className="input">
                <label>Enter field title</label>
                <input type="text" id="currentTitle" placeholder={`Eg. Enter your ${inputType === "short-text" ? "short text" : inputType === "long-text" ? "long text" : "number"}`} onChange={e => setTitle(e.target.value)} />
            </div>
            <div className="input inline">
                <label>Required: </label>
                <input type="checkbox" id="currentRequired" onChange={() => setRequired(!required)} />
            </div>
            <div className="input inline">
                <label>Page: </label>
                <input type="number" id="currentPage" onChange={e => setPage(parseInt(e.target.value))} />
            </div>
            {err && <p className="err mb-1">{err}</p>}
            {!fieldToUpdate && <button className="btn" onClick={addField}>Adicionar Campo</button>}
            {fieldToUpdate && <button className="btn" onClick={updateField}>Actualizar Campo</button>}
        </div>
    )
}

export default AddTextField