function visible() {
    let visible =   window.location.pathname.indexOf("/fill/") === -1 && window.location.pathname.indexOf("/order/") === -1 
  

    return visible
}

function Footer(){
    return (
        <>
            { visible() &&
                <div className="footer-wrapper">
                    <div className="footer container">
                        <div>
                            <a href="/" className="title mr-1">4coachsports</a>
                            <span>&copy; {new Date().getFullYear()}</span>
                        </div>
                    </div>
                </div>
            }
        </>
    )
}

export default Footer