import { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'

import { useParams } from 'react-router-dom'

import { getSubmissions, getForm } from "../db"

import SubmissionCard from "../components/SubmissionCard"

function Submissions(){
    const [loading, setLoading] = useState(true)
    const [msg, setMsg] = useState('')
    const [submissions, setSubmissions] = useState([])
    const [form, setForm] = useState(null)

    const { id } = useParams()

    useEffect(() => {
        if(!localStorage.getItem('gfc-user')) return
        const fetchData = async () => {
            try{
                let sbmns = await getSubmissions({ formID: id })
                let frm = await getForm({ id })
                setForm(frm)
                setSubmissions(sbmns)
                setLoading(false)
            }catch(e){
                setLoading(false)
                setMsg(e.message)
            }
        }
        fetchData()
    }, [id])

    return (
        <div className='create-form'>
            <h1 className="heading mb-1">Submissões</h1>
            <Link to={"/forms"} className="nav-item">Voltar atrás</Link>

            { loading ? <p className="text-center mt-1"><span className="spinner"></span></p>
            : msg ? <h3 className="msg mt-1">{msg}</h3>
            : submissions.length > 0 ? (
                <div className="cards-container submissions">
                    { 
                        submissions.sort(function(a,b)  {
                        return a.date && b.date ? new Date(a.date).getTime() - new Date(b.date).getTime() : true
                            }).reverse().map((subm, index) => <SubmissionCard formId={id} 
                                                                    key={index} 
                                                                    submission={subm.submission} 
                                                                    submissions={submissions} 
                                                                    submissionScope={subm}
                                                                    form={form}
                                                                    updateSubmissions={sub => setSubmissions(sub)} /> )
                    }
                </div>
            ) : <h3 className="msg mt-1">No submissions yet</h3>}
        </div>
    )
}

export default Submissions