import { useState, useEffect } from 'react'
import { v4 as uuidv4 } from 'uuid';

function AddPageSection({inputType, add, update, close, getMaxPage, fields, fieldToUpdate}){
    const [err, setErr ] = useState("")

    const [header, setHeader] = useState("")
    const [selectedPage, setPage] = useState()


    const addField = () => {

        if(!header.trim()) return setErr("Header is required")
        if(!selectedPage) return setErr("You need to select a page")
         
        let existingHeader = fields.filter(field => field.type === "page-section-header" && field.page === selectedPage).length > 0
        if(existingHeader) return setErr("You can only add a page section header once per page")

        add({
            id: uuidv4(),
            header,
            type: inputType,
            page: selectedPage
        })
        close()
    }

    const updateField = () => {
        if(!header.trim()) return setErr("Header is required")
        if(!selectedPage) return setErr("You need to select a page")

        fieldToUpdate.header = header
        fieldToUpdate.page = selectedPage
        
        update(fieldToUpdate)

        close()
    }

    useEffect(() => {
        
        let selec = document.getElementById("currentPage")
        let textElement = document.getElementById("currentHeader")

        if(fieldToUpdate) {
            textElement.value = fieldToUpdate.header
            selec.value = fieldToUpdate.page
            
            setHeader(fieldToUpdate.header)
            setPage(fieldToUpdate.page)
        } else {

            let maxValue = getMaxPage()
            selec.value = maxValue
            setPage(maxValue)
        }
    },[getMaxPage, fieldToUpdate])


    return (
        <div>
            <div className="input">
                <label>Header</label>
                <input type="text"  id="currentHeader" onChange={e => setHeader(e.target.value)} />
            </div>

            <div className="input inline">
                <label>Page: </label>
                <input type="number" id="currentPage" onChange={e => setPage(parseInt(e.target.value))} />
            </div>
            {err && <p className="err mb-1">{err}</p>}
            {!fieldToUpdate && <button className="btn" onClick={addField}>Adicionar Campo</button>}
            {fieldToUpdate && <button className="btn" onClick={updateField}>Actualizar Campo</button>}
        </div>
    )
}

export default AddPageSection