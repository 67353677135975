import InformationField from "./InformationField"
import RenderEditMode from "./RenderEditMode"
import TextInput from "./ui/TextInput"


function RenderFormComponents({ fields, openEditModal, deleteField, moveFieldUp, moveFieldDown }){
    return (
        <>
            {
                fields.map((field, index) =>  
                (
                    (["information-banner", "information-banner-text", "information-banner-image", "information-banner-image-text", "information-image-text", "information-image", "information-youtube", "information-youtube-banner"].indexOf(field.type) > -1) ? 
                    (  
                        <RenderEditMode field={field} openEditModal={openEditModal} deleteField={deleteField} moveFieldUp={moveFieldUp} moveFieldDown={moveFieldDown} component={
                            <>
                                <InformationField  controlKey={index} field={field}  />  
                             
                            </>
                        } />
                    )
                    :
                    ["short-text", "number", "long-text"].indexOf(field.type) > -1
                    ? (
                        <RenderEditMode field={field} openEditModal={openEditModal} deleteField={deleteField} moveFieldUp={moveFieldUp} moveFieldDown={moveFieldDown} component={ 
                            <div className={!field.headerPair ? "input card": "input"}>
                                <TextInput 
                                    type={field.type} 
                                    required={field.required} 
                                    controlKey={index} 
                                    label={field.title ?? field.inputText } 
                                />
                            </div> 
                        } />
                        
                    )
                    : field.type === "file" ? 
                    (
                        <RenderEditMode field={field} openEditModal={openEditModal} deleteField={deleteField} moveFieldUp={moveFieldUp} moveFieldDown={moveFieldDown} component={ 
                 
                                <div key={index} className={!field.headerPair ? "input card": "input"}>
                                    <label>{field.title}{field.required && <span className="err">*</span>}</label>
                                    <input type="file" />
                                </div>
                        } />

                    )
                    : field.type === "multioption-singleanswer" || field.type === "multioption-multianswer" ? 
                    (
                        <RenderEditMode field={field} openEditModal={openEditModal} deleteField={deleteField} moveFieldUp={moveFieldUp} moveFieldDown={moveFieldDown} component={ 
                            <div key={index} className={!field.headerPair ? "input card": "input"}>
                                    <label>{field.title}{field.required && <span className="err">*</span>}</label>
                                    {
                                        field?.imageBanner?.src && 
                                            <img class="multi-option-banner-image" alt="" src={field.imageBanner.src}  />
                                    }
                                    { field.options.map((option, idx) => (
                                        <div className="input inline" key={idx}>         
                                            <input type={field.type === "multioption-singleanswer" ? "radio" : "checkbox"} className="mr-1" name={field.title.replace(" ", "")} />
                                            <label>{option.text}</label>
                                        { option?.type &&  <input type={option.type} className="mr-1" name="inputs" /> }

                                        </div>
                                    )) }
                            </div>
                        } />
                                
                            
                    )
                    : field.type === "multioption-singleanswer-image" || field.type === "multioption-multianswer-image" ? 
                    (
                        <RenderEditMode field={field} openEditModal={openEditModal} deleteField={deleteField} moveFieldUp={moveFieldUp} moveFieldDown={moveFieldDown} component={ 
                            <div key={index} className={!field.headerPair ? "input card multioption-container": "input multioption-container"}>
                                    <label>{field.title}{field.required && <span className="err">*</span>}</label>
                                    {
                                        field?.imageBanner?.src && 
                                            <img class="multi-option-banner-image" alt="" src={field.imageBanner.src}  />
                                    }
                                        <div class="multioption-row multioption-row-wrapper list">
                                            { 
                                                field.options.map((option, idx) => (
                                                    <div className="multioption-item multioption-item-wrapper">
                                                        <div className="multioption-item-container input block col" key={idx}>                                
                                                            { option?.image?.src && 
                                                                <div>
                                                                    <img class="multi-option-image create-form" alt="" src={option.image.src} />
                                                                </div> 
                                                            }
                                                            <input type={field.type === "multioption-singleanswer-image" ? "radio" : "checkbox"} className="mr-1" name={field.title.replace(" ", "")} />
                                                            <label>{option.text}</label>
                                                            { option?.type &&  <input type={option.type} className="mr-1" name="inputs" /> }

                                                        </div>
                                                    </div>
                                                )) 
                                            }
                                        </div>

                                </div>
                        } />
                    )
                    : <p key={index}>Unknown field type.</p>
                ))
            }
        </>
    )
}

export default RenderFormComponents