import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.mode === 'light' ? '#6c7176' : '#308fe8',
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === 'light' ? '#8ab4f8' : '#308fe8',
  },
}));


function ProgressBar({width, maxValue, currentValue}){

  let percentage = ((100 * currentValue) / maxValue)

    return (
        <Box sx={{ width: width, flexGrow: 0.1, margin: "0 auto" }}>
            <BorderLinearProgress variant="determinate" value={percentage} />
        </Box>
    )
}

export default ProgressBar