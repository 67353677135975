import { useState, useEffect } from 'react'
import { arrayToggle } from '../../utils'
import { v4 as uuidv4 } from 'uuid';

function AddFileField({inputType, add, close, getMaxPage}){
    const [err, setErr ] = useState("")

    const availableTypes = ["ai", "psd", "png", "jpg", "jpeg", "pdf", "txt", ]

    const [title, setTitle] = useState("")
    const [required, setRequired] = useState(false)
    const [multipleFiles, setMultipleFiles] = useState(false)
    const [fileTypes, setFileTypes] = useState([])
    const [selectedPage, setPage] = useState()
    const [filesToUpload, setFileLimit] = useState(0)

    const addFileType = type => {
        let _fileTypes = [...fileTypes]
        arrayToggle(_fileTypes, type)
        setFileTypes(_fileTypes)
    }

    const addField = () => {
        if(!title.trim()) return setErr("Title is required")
        if(title.trim().length < 4) return setErr("Title should be atleast 4 characters long")
        if(!fileTypes.length) return setErr("Select atleast one file type")
        if(!selectedPage) return setErr("You need to select a page")

        let toAdd = {
            id: uuidv4(),
            title,
            required,
            multipleFiles,
            page: selectedPage,
            type: inputType,
            accepted: fileTypes
        }

        if( multipleFiles && filesToUpload > 0) {
            toAdd.filesToUpload = filesToUpload
        }

        add(toAdd)
        close()
    }

    useEffect(() => {
        let selec = document.getElementById("currentPage")
        let maxValue = getMaxPage()
        selec.value = maxValue
        setPage(maxValue)
    },[getMaxPage])

    return (
        <div>
            <div className="input">
                <label>Enter field title</label>
                <input type="text" placeholder="Upload your file" onChange={e => setTitle(e.target.value)} />
            </div>
            <div className="input">
                <label>Select acceptable file types</label>
                <div className="inline-inputs">
                    { availableTypes.map((type, index) => (
                        <div className="input inline" key={index}>
                            <input type="checkbox" className="mr-1" onChange={() => addFileType(type)} />
                            <label>.{type}</label>
                        </div>
                    )) }
                </div>
            </div>
            <div className="input inline">
                <label>Required: </label>
                <input type="checkbox" onChange={() => setRequired(!required)} />
            </div>
           
            <div className="input inline">
                    <label>Limit number of files: </label>
                    <input disabled={!multipleFiles} type="number" onChange={e => setFileLimit(parseInt(e.target.value))} />
                </div>
            <div className="input inline">
                <label>Allow multiple files: </label>
                
                <input type="checkbox" onChange={() => setMultipleFiles(!multipleFiles)} />
            </div>
            <div className="input inline">
                <label>Page: </label>
                <input type="number" id="currentPage" onChange={e => setPage(parseInt(e.target.value))} />
            </div>
            {err && <p className="err mb-1">{err}</p>}
            <button className="btn" onClick={addField}>Adicionar Campo</button>
        </div>
    )
}

export default AddFileField