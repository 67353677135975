import { HexColorPicker } from "react-colorful";
import { useState } from 'react'

export const ColorPicker = ({label, initialColor, onColorChange}) => {

  const [color, setColor] = useState(initialColor);

  function onChange(color) {
    setColor(color)
    onColorChange(color)
  }

  return  <> 
            <label>{label} {color}</label>
            <HexColorPicker color={color} onChange={onChange} />
          </>;
};