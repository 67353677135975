import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom"

import Navbar from "./components/Navbar"
import Footer from "./components/Footer"

//Importing pages
import Home from "./pages/Home"
import Create from "./pages/Create"
import Edit from "./pages/Edit"
import Order from "./pages/Order"
import Login from "./pages/Login"
import Forms from "./pages/Forms"
import Fill from "./pages/Fill"
import Submissions from "./pages/Submissions"

function App() {
  return (
    <div className="App">
        <Router>
          <Navbar />
          <div className="container main ">
            <Routes>
              <Route path="/" element={<Home/>} exact />
              <Route path="/create" element={<Create/>} />
              <Route path="/edit/:id" element={<Edit/>} />
              <Route path="/order/:id" element={<Order/>} />
              <Route path="/login" element={<Login/>} />
              <Route path="/forms" element={<Forms/>} />
              <Route path="/fill/:id" element={<Fill/>} />
              <Route path="/submissions/:id" element={<Submissions/>} />
            </Routes>
          </div>
        </Router>
        <Footer />
    </div>
  );
}

export default App;
