import { useEffect, useRef } from 'react';

import AddTextField from '../create/AddTextField';
import AddInformationField from '../create/AddInformationField';
import AddMultiOptionField from '../create/AddMultiOptionField';
import AddFileField from '../create/AddFileField'
import AddPageSection from '../create/AddPageSection'


function AddFieldModal({ inputType, add, update, close, getMaxPage, fields, fieldToUpdate }){
    const myRef = useRef();

    const handleClickOutside = e => {
        if (!myRef.current.contains(e.target)) {
            close();
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    });


    return (
        <div className="modal">
            <div className="modal-content add-field" ref={myRef}>
                <span className="close" onClick={close}>&times;</span>
                {
                     (["page-section-header"].indexOf(inputType) > -1) ? (
                        <AddPageSection inputType={inputType} add={add} update={update} close={close} getMaxPage={getMaxPage} fields={fields} fieldToUpdate={fieldToUpdate} />
                    ) :
                    (["information-banner", "information-banner-text", "information-banner-image", "information-banner-image-text", "information-image-text", "information-image", "information-youtube", "information-youtube-banner"].indexOf(inputType) > -1) ? (
                        <AddInformationField inputType={inputType} add={add} update={update} close={close} getMaxPage={getMaxPage} fieldToUpdate={fieldToUpdate} />
                    ) : (["short-text", "long-text", "number"].indexOf(inputType) > -1) ? (
                        <AddTextField inputType={inputType} add={add} update={update} close={close} getMaxPage={getMaxPage} fieldToUpdate={fieldToUpdate} />
                    ) : (["multioption-singleanswer", "multioption-multianswer", "multioption-singleanswer-image", "multioption-multianswer-image"].indexOf(inputType) > -1) ? (
                        <AddMultiOptionField inputType={inputType} add={add} update={update} close={close} getMaxPage={getMaxPage}  fieldToUpdate={fieldToUpdate} />
                    ) : (inputType === "file") ? (
                        <AddFileField inputType={inputType} add={add} update={update} close={close} getMaxPage={getMaxPage} fieldToUpdate={fieldToUpdate} />
                    ) : <p>Unknown type</p>
                }
            </div>
        </div>
    )
}

export default AddFieldModal