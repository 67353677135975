function Heading({ form }){
    function getBackgroundColor() {
        let style = { };

        if (form.backgroundColor) {
            style.backgroundColor = form.backgroundColor
        }
        
        return style;
    }
    
    return (
        <>
            {  form?.topBanner?.src  &&
                <div className="topBanner-container">
                    <img class="" alt="" src={form.topBanner.src} />
                
                </div>
            }
            { form?.title &&
                <div className="heading-wrapper ">
                 
                    <div className="head" style={getBackgroundColor()}></div>

                    <div className="title">
                        <h1>{form.title}</h1>
                    </div>

                    { form.info && 
                        <>
                            <hr />
                            <div className="info">
                                <p>{form.info}</p>
                            </div>
                        </>
                    }
                    
                </div>
            }
        </>
    )
}

export default Heading

