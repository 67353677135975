import { useState, useEffect } from 'react'
import { useParams } from "react-router-dom"
import { getSubmissionDocument } from "../db"
import {getSubmissionAsAttachment, getEmailInformation} from '../utils/email'

function Order(){
    const { id } = useParams()
    const [msg, setMsg] = useState('')
    const [loading, setLoading] = useState(true)
    
    useEffect(() => {
        if(!localStorage.getItem('gfc-user')) return
        const fetchData = async () => {
            try{
                let document = await getSubmissionDocument(id)
                await getSubmissionAsAttachment(document.submission, getEmailInformation(id).filename, true)
                
            }catch(e){
                setLoading(false)
                setMsg(e.message)
            }
        }
        fetchData()
    }, [id])

    return <div>
            { 
                loading ? <p className="text-center mt-1"><span className="spinner"></span></p> 
                    : msg  ? <h3 className="msg mt-1">{msg}</h3> 
                    : <h3 className="mt-1">Downloaded</h3>
            }

    </div>
}

export default Order