import { useState } from 'react'

import { arrayToggle, getOriginalOptionFromField } from "../utils"
import TextInput from "./ui/TextInput"
import CustomMultiOptionField from "./ui/CustomMultiOptionField"
import {setChecked, setUnchecked, isChecked } from "../store/store.js"


function MultiOptionField({fieldModel, onSelected, page, originalFillableModel }){
    const [selected, setSelected] = useState([])
    const [imgSingleSelected, setImgSingleSelected] = useState("")
    
    function manageSingleImgSelected(elementId) {
         if(fieldModel.type.indexOf("single") !== -1) {
       
            if(imgSingleSelected !== "") {
                document.getElementById(imgSingleSelected).classList.remove('selected');
            }
            
            setImgSingleSelected(elementId)
         }
    }

    function removeSingleImgSelected(elementId) {
        document.getElementById(elementId).classList.remove('selected');
        setImgSingleSelected("")
    }

    const addOption = (opt, other) => {
        let _selected = [...selected]
        if(fieldModel.type === "multioption-multianswer" || fieldModel.type === "multioption-multianswer-image"){
            arrayToggle(_selected, opt, other)
        }else{
           _selected = [opt]

        }
        setSelected(_selected)
        onSelected(_selected)
    }

    const addOtherTextOption = (fieldModel, opt, value) => {
        opt.value = value;

        checkOrUncheck(fieldModel, opt, true)
        
        if(imgSingleSelected !== "") {
            removeSingleImgSelected(imgSingleSelected)
        }

        addOption(opt, true);
    }

    const addOptionWithImage = (fieldModel, opt) => {
        let selectImage = opt.id + "_img";

        const image = document.getElementById(selectImage);

        if(fieldModel.type.indexOf("image") !== -1) {

            let checked = checkOrUncheck(fieldModel, opt)

            if(checked) {
                image.classList.add('selected');
            } else if(image.classList.contains("selected")) {
                image.classList.remove('selected');
            }

            manageSingleImgSelected(selectImage)

            addOption(opt)
        } 
    }

    return (
        <div className={fieldModel.type.indexOf("image") !== -1 ? (!fieldModel.headerPair ? "multioption-container card" : "multioption-container") : (!fieldModel.headerPair ? "input card": "input")}>
            <label className="mb-2-i">{fieldModel.title}{fieldModel.required && <span className="err">*</span>}</label>
            {
                fieldModel?.imageBanner?.src && 
                    <img class="multi-option-banner-image" alt="" src={fieldModel.imageBanner.src}  />
            }
            <div className={fieldModel.type.indexOf("image") !== -1 ? "multioption-row multioption-row-wrapper list" : "list"}>
                {
                    fieldModel.options.map((option, index) => (
                        <div className={fieldModel.type.indexOf("image") !== -1 ? "multioption-item multioption-item-wrapper" : "input inline"} key={index}>
                            <div className='multioption-item-container'>
                                { 
                                    option?.image?.src && 
                                        <div id={option.id + "_img"} className="image-container card card-shadow" onClick={() => addOptionWithImage(fieldModel, option)}>
                                            <img class="multi-option-image" alt="" src={option.image.src}  />
                                        </div>
                                } 
                                <div className={fieldModel.type.indexOf("single") !== -1 ? "": "option-container"}>
                                    <CustomMultiOptionField option={option} 
                                                            addOption={addOption} 
                                                            fieldModel={fieldModel}  
                                                            id={fieldModel.id + "_" + option.id} 
                                                            name={fieldModel.id + "_" + option.id} />


                                    {
                                        option.type === "other-option" &&
                                            <TextInput 
                                                className="mr-1"
                                                type={"short-text"} 
                                                required={false} 
                                                controlKey={index} 
                                                fillableValue={getOriginalOptionFromField(fieldModel, originalFillableModel, option)}
                                                other={true}
                                                onChange={e => addOtherTextOption(fieldModel, option, e.target.value)} 
                                            />
                                    }
                                </div>
                            </div>
                        </div>
                    ))
                }
            </div>
        </div>
    )
}

export default MultiOptionField

function checkOrUncheck(fieldModel, opt, other) {
    const type = (fieldModel.type === "multioption-multianswer" || fieldModel.type === "multioption-multianswer-image") ? "checkbox" : "radio"

    let isOptionChecked = isChecked({
        id: fieldModel.id,
        optionId: opt.id,
        type: type
    })

    if (!isOptionChecked) {
        setChecked({
            id: fieldModel.id,
            optionId: opt.id,
            type: type
        })
    } else if(!other) {
        setUnchecked({
            id: fieldModel.id,
            optionId: opt.id,
            type: type
        })
    }
 
    return isChecked({
        id: fieldModel.id,
        optionId: opt.id,
        type: type
    })
}