
import { updateArrOfObjStateWithId,updateArrOfObjStateFileWithId,updateArrOfObjStateFilesWithId, removeArrOfObjStateFilesWithId, getOriginalField} from "../utils"

import MultiOptionField from "./MultiOptionField"
import FileField from "./FileField"
import InformationField from "./InformationField"

import TextInput from "./ui/TextInput"

function RenderReactiveFormComponents({ setFillableModel, fillableModel, originalFillableModel, page, loading, setLoading }){

    return (
        <>
             { 
                (fillableModel).map((field, index) => 
                (
                    (["information-banner", "information-banner-text", "information-banner-image", "information-banner-image-text", "information-image-text", "information-image", "information-youtube", "information-youtube-banner"].indexOf(field.type) > -1) ? 
                    (<InformationField required={field.required} 
                                       controlKey={index} 
                                       field={field} 
                                       fillableValue={getOriginalField(field, originalFillableModel)}
                                       onTextChange={text => updateArrOfObjStateWithId(setFillableModel, field, originalFillableModel, "value", text)} />)    
                    :
                    ["short-text", "number", "long-text"].indexOf(field.type) > -1
                    ? (
                       <div className={!field.headerPair ? "input card": "input"}>
                            <TextInput 
                                type={field.type} 
                                required={field.required} 
                                controlKey={index} 
                                label={field.title ?? field.inputText } 
                                fillableValue={getOriginalField(field, originalFillableModel)}
                                onChange={e => updateArrOfObjStateWithId(setFillableModel, field, originalFillableModel, "value", e.target.value)}
                                validation={field?.validation} 
                            />
                        </div> 
                    ) : (field.type === "multioption-singleanswer" || field.type === "multioption-singleanswer-image" || field.type === "multioption-multianswer" || field.type === "multioption-multianswer-image") ? (
                       
                        <MultiOptionField key={field.id} 
                                          fieldModel={field} 
                                          originalFillableModel={originalFillableModel}
                                          onSelected={res => updateArrOfObjStateWithId(setFillableModel, field, originalFillableModel, "value", res)} 
                                          page={page}/>
                        
                    ) : field.type === "file" ? (
                        <FileField key={index} 
                                   fieldModel={field} 
                                   originalFillableModel={getOriginalField(field, originalFillableModel)} 
                                   onCompleted={(downloadUrl,fileName) => updateArrOfObjStateFileWithId(setFillableModel, field, originalFillableModel, downloadUrl, fileName)}
                                   onCompletedFiles={(filesToSave) => updateArrOfObjStateFilesWithId(setFillableModel, field, originalFillableModel, filesToSave)}
                                   onRemoveFiles={(file) => removeArrOfObjStateFilesWithId(setFillableModel, field, originalFillableModel, file)}
                                   loading={loading}
                                   setLoading={setLoading}
                                   
                                    />
                    ) : <p key={index}>Unknown field type: {field.type} </p>
                ))
            }
        </>
    )
}

export default RenderReactiveFormComponents