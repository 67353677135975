let selectedFormFieldOption = []


function getOption(wrapper) {
    return selectedFormFieldOption.find(ctrl => ctrl.id === wrapper.id && ctrl.optionId === wrapper.optionId)
}

export function setChecked(wrapper) {
    if(wrapper.type === "radio") {
        selectedFormFieldOption = selectedFormFieldOption.filter( ctrl =>  ctrl.id !== wrapper.id)
        selectedFormFieldOption.push(wrapper)

    } else {
        let checked = getOption(wrapper)  !== undefined

        if(!checked){        
            selectedFormFieldOption.push(wrapper) 
        } else {
            selectedFormFieldOption = selectedFormFieldOption.filter(ctrl =>  ctrl.optionId !== wrapper.optionId)
        }
    }
}


export function setUnchecked(wrapper) {
    if(wrapper.type === "radio") {
        selectedFormFieldOption = selectedFormFieldOption.filter( ctrl =>  ctrl.id !== wrapper.id)
    } else if(getOption(wrapper)  !== undefined){
        selectedFormFieldOption = selectedFormFieldOption.filter(ctrl =>  ctrl.optionId !== wrapper.optionId)
    }
}

export function isChecked(wrapper) {
    return getOption(wrapper) !== undefined
}



