import {setChecked, isChecked } from "../../store/store.js"


function CustomMultiOptionField({addOption, option, fieldModel, id, name}){

    const type = (fieldModel.type === "multioption-multianswer" || fieldModel.type === "multioption-multianswer-image") ? "checkbox" : "radio"

    const handleOnChange = () => {

        setChecked({
            id: fieldModel.id,
            optionId: option.id,
            type: type
        })
        
        addOption(option)
      };        


    return (
        <>
            {
                    <input type={type} 
                           className="mr-1" 
                           id={id} 
                           name={name}  
                           checked={isChecked({
                            id: fieldModel.id,
                            optionId: option.id,
                            type: type
                           })}
                           onChange={handleOnChange} />
            }

            <label onClick={handleOnChange}>{option.text}</label>

        </>
    )
}

export default CustomMultiOptionField