// import { Link } from 'react-router-dom'


function Home(){
    return <div>
        <div className="section">
            UNDER CONSTRUCTION
        </div>
    
    </div>
}

export default Home