import { useEffect, useRef } from 'react';

function SubmissionDetailModal({ submission, close, submissionScope }){
    const myRef = useRef();

    const handleClickOutside = e => {
        if (!myRef.current.contains(e.target)) {
            close();
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    });


    return (
        <div className="modal">
            <div className="modal-content submission" ref={myRef}>
                <span className="close" onClick={close}>&times;</span>
                <h4>Information:</h4>
                <br/>

                <h5>Browser:</h5>
                <p>{submissionScope.agent}</p>
                <br/>

                <h4>Submission:</h4>

                {
                    submission.map((subm, index) => (
                        <div className="card input">
                            <h5>{subm.title ?? subm.text}</h5>
                            <br/>
                            {
                            (subm.type === "multioption-singleanswer" ||  subm.type === "multioption-singleanswer-image") || (subm.type === "multioption-multianswer" || subm.type === "multioption-multianswer-image")
                            ? subm.value.map((v, index) =>                         
                                v?.image?.src ? 
                                <>
                                    <img class="multi-option-image" alt="" src={v.image.src} />
                                    <p key={index} className="li"> ✅ {v.text} ({v.image.value})</p>
                                </> : 
                                <>
                                    <p key={index} className="li"> ✅ {v.value ? `${v.text}: ` + v.value : v.text} </p>
                                </>
                            )                    
                            : subm.type === "file"
                            ? subm.files ? <>
                                        <ul>
                                            {subm.files.map((v, index) => <li><a href={v.value} rel="noreferrer" target="_blank" className="link">{v.fileName}</a></li>)}
                                        </ul>
                                    </> 
                                :  <a href={subm.value} rel="noreferrer" target="_blank" className="link">{subm.value}</a>
                            : <p>{subm.value}</p>}
                        </div>
                    ))
                }
            </div>
        </div>
    )
}

export default SubmissionDetailModal