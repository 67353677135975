import RenderFormComponents from "./RenderFormComponents"

function PageSectionField({  header, field, controlKey, backgroundColor, openEditModal, deleteField }){

    function getFieldArray() {
        return new Array(field);
    }

    function getBackgroundColor() {
        let style = { };

        if (backgroundColor) {
            style.backgroundColor = backgroundColor
        }
        
        return style;
    }
    return (
        <>
            {
                header &&
                <div className="heading-wrapper" key={controlKey}>
                    <div className="head section-header" style={getBackgroundColor()}>
                        <label className="block">{header.header}</label>  
                    </div>

                    {
                        field && 
                        <div className="text section-header">
                            <RenderFormComponents fields={getFieldArray()}  openEditModal={openEditModal} deleteField={deleteField} />
                        </div>
                    }
                </div>
            }
        </>
    )
}

export default PageSectionField

