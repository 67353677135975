
import { useState } from 'react'

import ImagePicker from 'react-image-picker'
import 'react-image-picker/dist/index.css'

const contents = require.context('../assets/images/', true, /\.(jpg|png|jpeg|gif|JPG|PNG|JPEG|GIF)$/)

let options = []

let imageList = contents.keys().map( (key) => {

  let option = key.replace("./", "")

  option = option.substring(0, option.indexOf("/") )

  if(!options.find(opt => opt === option)) {
    options.push(option)
  }

  let content = contents(key)

  if(content.indexOf("base64") === -1) {
    content = window.location.origin + contents(key)
  }

  return {
    name: key,
    url: content,
    option: option
  }
})

options = options.sort()

function SelectImage({onImageSelected}){
  const [selectedOption, setSelectedOption] = useState("");


  function onPick(selectedImage) {
    onImageSelected(selectedImage);
  }
    return (
      <div>
        <div className='image-selector-header'>
          <label for="image-types">Escolha um tipo de imagem:</label>
          <select
            name="image-types"
            value={selectedOption}
            onLoad={!selectedOption ? setSelectedOption(options[0]) : ""}
            onChange={e => setSelectedOption(e.target.value)}>
            {options.map(o => (
              <option key={o} value={o}>{o}</option>
            ))}
          </select>
        </div>
        <ImagePicker 
          images={imageList.filter(img => img.option === selectedOption).map((img, i) => ({src: img.url, value: img.name}))}
          onPick={onPick}
        />
      </div>
    )
}

export default SelectImage