import firebase from 'firebase/compat/app';
import { getAnalytics } from "firebase/analytics";
import 'firebase/compat/analytics';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import "firebase/compat/storage";

const firebaseConfig = {
    apiKey: "AIzaSyAXXsH-v94OsqJ1U_Dr36yT_UlrXjBOWQg",
    authDomain: "coachpt-550ad.firebaseapp.com",
    projectId: "coachpt-550ad",
    storageBucket: "coachpt-550ad.appspot.com",
    messagingSenderId: "493276986698",
    appId: "1:493276986698:web:6a856a9ce9a65393a6d5b3",
    measurementId: "G-1D26DPQNBJ"
  };


const app = firebase.initializeApp(firebaseConfig);

// eslint-disable-next-line no-unused-vars
const analytics = getAnalytics(app);

export const auth = firebase.auth()
export const firestore = firebase.firestore()
firestore.settings({ ignoreUndefinedProperties: true })
export const storage = firebase.storage()
