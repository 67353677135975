import TextInput from "./ui/TextInput"


function InformationField({ required, field, onTextChange, controlKey, fillableValue }){
    function getTitle() {
        return field.title ?? field.inputText
    }
    return (
        <div  className="input">
            <div className={ !field.headerPair ? "input card render block" : "input render block"}>
              
                {
                    field.type.indexOf("banner") !== -1 &&  
                        <label className="block preserve-text">{field.text}{required && <span className="err">*</span>}</label>        
                }
                
                {
                    field.type.indexOf("image") !== -1 &&
                        <img class="multi-option-image block add-field preview multi-option-banner-image" alt="" src={field.image.src} />
                }

                {
                    field.type.indexOf("youtube") !== -1 &&
                        <iframe class="youtube-embed" width="560" height="315" title="Youtube Video" src={field.youtube} frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                }

                {
                    field.type.indexOf("text") !== -1 &&
                    <>
                        {
                            getTitle() && 
                                <label className="preserve-text">{getTitle()}{required && <span className="err">*</span>}</label>
                        }
            
                        <TextInput  className="block" 
                                    type={"short-text"} 
                                    required={required} 
                                    controlKey={controlKey} 
                                    fillableValue={fillableValue}
                                    onChange={e => onTextChange(e.target.value)}  />
                    </>
                }
            </div>
        </div>
    )
}

export default InformationField

