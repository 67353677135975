import * as React from 'react';
// import Box from '@mui/material/Box';
// import Grid from '@mui/material/Grid';
import ProgressBar from "./ProgressBar"


function Pagination({decrementPage, incrementPage, getMaxPage, page, handleSubmit, loading}){

      return (
        <div className="page-controls">

                <div className="page-navigation">
                  
                  { 
                    loading ?
                    <>
                      <span className="spinner"></span> 
                    </>
                    :
                    <>
                      {
                          page !== 1 &&
                            <button className="btn" onClick={decrementPage}>Back</button>
                      }

                      {
                          page !== getMaxPage() && 
                            <button className="btn" onClick={incrementPage}>Next</button>
                      }

                      {
                          page === getMaxPage() && handleSubmit ? 
                            <button className="btn" onClick={handleSubmit}>Submit</button>
                            : false
                      }
                    </>
                  }
                </div>
                
                <div className="page-stats">

                  <span>Page {page} of {getMaxPage()}</span>
                </div>    
                <div className="page-progress">
                  <ProgressBar  width={"100%"} currentValue={page} maxValue={getMaxPage()}/>
                </div>                

        </div>
      )
  }
  
  export default Pagination